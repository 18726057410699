<template>
  <div>
    <v-app-bar light color="white" fixed>
      <v-toolbar-title class="primary--text">
        <h1 class="px-2 py-1 textLogoHeader">CommunityWare</h1>
        <!-- <v-img max-width="85" src="../assets/logo-rodape.png"></v-img> -->
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-icon large class="primary--text" style="font-size: 32px; margin-top: -8px">shopping_basket</v-icon>
      <span class="pl-2 primary--text">Marketplace logo</span>
      <v-spacer></v-spacer>
      <!-- <v-menu transition="slide-y-transition" left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon>
            <v-icon mediun color="primaray" v-bind="attrs" v-on="on"
              >settings</v-icon
            >
          </v-btn>
        </template>
        <v-list nav dense>
          <v-list-item-group
            v-model="group"
            active-class="blue--text text--darken-1"
          >
            <v-list-item
              v-for="(menuItem, i) in configMenu"
              :key="i"
              :to="menuItem.path"
            >
              <v-list-item-icon class="mr-4">
                <v-icon>{{ menuItem.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ menuItem.text }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu> -->
      <v-menu :close-on-content-click="false" :nudge-width="200" offset-x>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" icon v-bind="attrs" v-on="on">
            <v-icon mediun color="primaray">mdi-account-circle</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar class="mt-0">
                <v-icon style="font-size: 42px">mdi-account-circle</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ userName }}</v-list-item-title>
                <v-list-item-subtitle>Empresa / Cargo</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list>
            <v-list-item disabled>
              <v-list-item-icon class="mr-4">
                <v-icon class="pl-1" disabled>assignment_ind</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Meus dados</v-list-item-title>
            </v-list-item>

            <v-list-item @click="logout">
              <v-list-item-icon class="mr-4">
                <v-icon class="pl-1">logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Sair do sistema</v-list-item-title>
            </v-list-item>
          </v-list>
          <!-- <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="menu = false"> Cancel </v-btn>
            <v-btn color="primary" text @click="menu = false"> Save </v-btn>
          </v-card-actions> -->
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer style="
        height: calc(100% - 65px);
        border-top: 1px solid rgb(0, 0, 0) !important;
      " light v-model="drawer" fixed permanent expand-on-hover class="mt-16">
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="blue--text text--darken-1">
          <v-list-item :disabled="menuItem.disab ? true : false" v-for="(menuItem, i) in admMainMenu" :key="i"
            :to="menuItem.path">
            <v-list-item-icon class="mr-4">
              <v-icon>{{ menuItem.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ menuItem.text }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  beforeMount() {
    this.verifyServices();
  },
  mounted() {
    // console.log("ddd", this.$router.options.routes[2].children);
    //this.buildMenu();
  },
  computed: {
    closeId() {
      return localStorage.getItem("userId");
    },
    userName() {
      return localStorage.getItem("userName")
        ? localStorage.getItem("userName")
        : "Aguardando nome do usuário";
    },
    configMenu() {
      return this.$router.options.routes[1].children.filter((menuItem) => {
        //console.log("menu");
        if (menuItem.config) {
          return menuItem;
        }
      });
    },
    admMainMenu() {
      return this.$router.options.routes[1].children.filter((menuItem) => {
        // console.log('asasasda')
        if (menuItem.adm) {
          if (menuItem.name == "billingProfile") {
            menuItem.disab = this.disabTittles
          }
          return menuItem;
        }
      });
    },
  },
  methods: {
    verifyServices() {
      var obj = {
        url: "api/service/get-all",
        query: {},
        method: "get",
      }
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].prefix == "pagamento") {
            this.disabTittles = false
          }
        }
      })
    },
    logout() {
      const _this = this;
      let obj = {
        url: "/api/logout",
        query: { user_id: this.closeId },
        method: "post",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        this.$store.dispatch("setCookie", {
          cname: "loginUrl",
          cvalue: "",
          exdays: 1,
        });
        if (response) {
          localStorage.clear();
          _this.$router.push("/login");
        }
      });
      return true;
    },
    buildMenu() { },
    toogleDrawer() {
      alert("ss");
      this.drawer = !this.drawer;
    },
  },
  data() {
    return {
      disabTittles: true,
      group: null,
      drawer: true,
      mini: true,
      items: [
        { title: "Home", icon: "mdi-home-city" },
        { title: "My Account", icon: "mdi-account" },
        { title: "Users", icon: "mdi-account-group-outline" },
      ],
    };
  },
};
</script>

<style>
.textLogoHeader {
  font-size: 18px;
  color: white;
  background: #bbb;
  border-radius: 10px;
}
</style>