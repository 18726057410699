<template>
  <div style="margin-top: 75px;">
    <AppBar />
    <router-view />
  </div>
</template>

<script>
import AppBar from "../components/AppBar";
export default {
  components: {
    AppBar,
    //GridFinancial,
  },
  mounted() {
    //this.$router.push("/partners").catch(() => {});
    // this.$router.push("/partners");
  },
};
</script>

<style>
.v-tooltip__content {
  font-size: 12px !important;
  padding: 3px 10px !important;
  border-radius: 2px !important;
}

.miniTtip {
  font-size: 11px !important;
}

.v-tooltip__content.min {
  padding: 2px 6px;
}
</style>